<template>
  <div class="section-with-image-and-items py-5">
    <div class="row">
      <div class="col-12 text-center container">
        <div class="container rounded">
          <b-tabs content-class="mt-3" align="center">
            <b-tab class="row" title="Cómo comprar" active>
              <div
                class="col-3 float-left mt-5 mb-5"
                v-for="(card, idx) in props.cards"
                v-bind:key="idx"
              >
                <div>
                  <b-card
                    v-if="card.btnTxt"
                    tag="article"
                    style="max-width: 20rem"
                    class="mb-2 cardBox"
                  >
                    <div class="logoBox bg-primary container">
                      <img class="logo" v-bind:src="card.logo" />
                    </div>
                    <h6 class="mt-3">
                      {{ card.title }}
                    </h6>
                    <b-card-text class="mt-2">
                      {{ card.description }}
                    </b-card-text>

                    <b-button class="" href="#" variant="primary">{{
                      card.btnTxt
                    }}</b-button>
                  </b-card>
                  <b-card
                    v-else
                    tag="article"
                    style="max-width: 20rem"
                    class="mb-2 cardBox"
                  >
                    <div class="logoBox bg-primary container">
                      <img class="logo" v-bind:src="card.logo" />
                    </div>
                    <h6 class="mt-3 bold">
                      {{ card.title }}
                    </h6>
                    <b-card-text class="mt-2">
                      {{ card.description }}
                    </b-card-text>
                  </b-card>
                </div>
              </div>
              <div
                class="container"
                v-for="(item, idx) in props.tab1"
                v-bind:key="idx"
              >
                <div>
                  <p>{{ item.description2 }}</p>
                  <img class="mt-5" v-bind:src="item.imageURL" />
                </div>
              </div>
            </b-tab>
            <b-tab title="Sea un corredor o intermediario">
              <div
                class="container"
                v-for="(item, idx) in props.tab2"
                v-bind:key="idx"
              >
                <p class="pt-5 pb-5">{{ item.description }}</p>
                <b-button class="mb-5 " variant="primary">{{ item.btnTxt }}</b-button>
                <div class="mb-5 buttont"></div>
              </div>
            </b-tab>
            <b-tab title="Sea un proveedor de servicio"
              ><p></p>
              <div
                class="container"
                v-for="(item, idx) in props.tab3"
                v-bind:key="idx"
              >
                <p class="pt-5 pb-5">{{ item.description }}</p>
                <b-button class="mb-5" variant="primary">{{ item.btnTxt }}</b-button>
                <div class="mb-5 buttont"></div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- img-src="https://picsum.photos/600/300/?image=25"
                  img-alt="Image"
                  img-top -->

<script>
export default {
  props: {
    props: Object,
  },
};
</script>

<style lang="css" scoped>
.lol {
  border: 1px solid black;
}

.cardBox {
  height: 320px;
  font-size: 17px;
}

.logo {
  width: 60px;
  height: 60px;
}

.logoBox {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 100px;
}

.buttont {
  padding-bottom: 500px;
}

</style>
